<template>
  <div class="app-container">
    <el-row :gutter="4" type="flex" justify="center">
      <el-col :span="20">
        <el-card class="box-card">
          <div slot="header" class="clearfix" justify="center" align="center">
            <strong><span>Editar Logro</span></strong>
          </div>
          <div class="text item">
            <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form @keypress.enter.native.prevent="handlerUpdate" v-on:submit.prevent label-position="top"
                class="size-form-item" label-width="120px" :model="form">
                <el-row :gutter="10" justify="space-around" align="middle">
                  <el-col :sm="24" :md="24" :lg="24" align="middle">
                  </el-col>
                </el-row>
                <el-row justify="space-around">
                  <el-col :sm="24" :md="12" :lg="12" align="middle">
                  <el-form-item label="Icono de Logro">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.icon"
                      @getImage="getSelectedIcon"
                    ></ImageUpload>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="12" align="middle">
                  <el-form-item label="Icono de Logro Bloqueado">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.iconDisabled"
                      @getImage="getSelectedIconDisabled"
                    ></ImageUpload>
                  </el-form-item>
                </el-col>
              </el-row>
                <!-- <el-row :gutter="10" justify="space-around">
                  <el-col :sm="24" :md="24" :lg="24">
                    <el-form-item label="Vigencia de logro" align="left">
                      <ValidationProvider name="Vigencia de logro" v-slot="{ errors }">
                        <el-date-picker v-model="form.expiration" type="date" placeholder="Selecciona una fecha">
                        </el-date-picker>
                        <br />
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                  </el-col>
                </el-row> -->
                <el-row :gutter="10" justify="space-around">
                  <el-col :sm="24" :md="24" :lg="24">
                    <el-form-item label="Tipo" align="left" v-if="form.type != 'programmed'">
                      <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
                        <el-select v-model="form.type" clearable placeholder="Select" disabled>
                          <el-option v-for="item in types" :key="item.value" :label="traslatedType(item.label)"
                            :value="item.value"></el-option>
                        </el-select>
                        <br />
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Título" align="left">
                      <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.title"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Nombre de Clave">
                      <ValidationProvider name="Nombre de Clave" rules="required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.keyName" disabled></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Descripción de logro desbloqueado">
                      <ValidationProvider name="'Descripción de logro desbloqueado'" rules="required" v-slot="{ errors }">
                        <el-input type="textarea" :rows="4" v-model="form.description"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Descripción de logro bloqueado">
                      <ValidationProvider name="'Descripción de logro bloqueado'" rules="required" v-slot="{ errors }">
                        <el-input type="textarea" :rows="4" v-model="form.descriptionDisabled"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                  <el-row :gutter="10" justify="space-around">
                    <el-col :sm="24" :md="24" :lg="24" align="left">
                      <el-form-item label="Patrocinador" align="left">
                        <ValidationProvider name="Patrocinador" v-slot="{ errors }">
                          <el-select v-model="form.sponsor" clearable filterable allow-create placeholder="Select">
                            <el-option
                              v-for="item in sponsors"
                              :key="item.sponsor"
                              :label="item.sponsor"
                              :value="item.sponsor">
                            </el-option>
                          </el-select>
                          <br/>
                          <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </el-form-item>
                      <el-form-item label="Recompensa" align="left">
                        <ValidationProvider name="Recompensa" v-slot="{ errors }">
                          <el-input type="textarea" :rows="4" size="mini" v-model="form.reward"></el-input>
                          <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </el-form-item>
                      <el-form-item label="URL - ¿Como obtener la recompensa?">
                        <ValidationProvider name="URL" v-slot="{ errors }">
                          <el-input  v-model="form.urlObtainReward"></el-input>
                          <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </el-form-item>
                    </el-col>
                  </el-row>
                <el-row justify="space-around" align="middle">
                  <el-col :sm="24" :md="24" :lg="24" align="middle">
                    <el-form-item label="Mostrar logro en aplicación">
                      <el-switch
                        v-model="form.showInApp"
                        active-text="Si"
                        inactive-text="No">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="center">
                  <el-col :xs="8" :md="4" align="middle">
                    <el-form-item>
                      <el-button @click.prevent="handlerUpdate" type="primary" icon="el-icon-upload2" :disabled="invalid">
                        Actualizar
                      </el-button>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="8" :md="4" align="middle">
                    <el-form-item>
                      <el-button @click.prevent="handlerCancel" type="danger">
                        Cancelar
                      </el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </ValidationObserver>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash'
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { updateAchievement, getAchievement, filterData } from '@/api/achievements.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'editProject',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    ImageUpload
  },
  data () {
    return {
      types: [
        {
          value: 'qr',
          label: 'Codigo QR'
        },
        {
          value: 'customized',
          label: 'Personalizado'
        }
      ],
      host: urlServer,
      form: {
        _id: '',
        title: '',
        keyName: '',
        description: '',
        icon: null,
        showInApp: true,
        type: 'qr',
        iconDisabled: null,
        descriptionDisabled: '',
        urlObtainReward: ''
      },
      sponsors: null,
      loadingImage: false,
      imageSelected: false,
      backupForm: ''
    }
  },
  mounted () {
    this.fetchFilterSponsors()
    this.fetchData()
  },
  methods: {
    reformatKey () {
      this.form.keyName = this.form.keyName.replace(/\s/g, '_')
    },
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedIcon (iconUploaded) {
      this.form.icon = iconUploaded
      console.log('imagen cargada en formulario ' + this.form.icon)
    },
    getSelectedIconDisabled (iconUploaded) {
      this.form.iconDisabled = iconUploaded
      console.log('imagen cargada en formulario ' + this.form.iconDisabled)
    },
    async fetchFilterSponsors () {
      const formData = new FormData()
      formData.append('fieldSearch', 'sponsor')
      filterData(formData)
        .then((response) => {
          this.sponsors = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async fetchData () {
      try {
        console.log(this.id)
        const response = await getAchievement(this.id)
        if (response.success) {
          console.log('EDIT RESPONSE  | ', response)
          this.backupForm = JSON.parse(JSON.stringify(response.data))
          this.form = await this.traslatedType(response.data)
        } else {
          console.log(response)
        }
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      }
    },
    handlerCancel () {
      this.$router.push('/achievements')
    },
    async handlerUpdate (info) {
      console.log(this.form)
      if (this.form.icon !== '' && this.form.icon !== null) {
        if (_.isEqual(this.form, this.backupForm)) {
          this.$router.push('/achievements')
        } else {
          try {
            console.log(this.form)
            const response = await updateAchievement(this.form, this.form._id)
            if (response.success) {
              console.log(response)
              this.$message({
                showClose: true,
                message: '¡Se actualizó correctamente!',
                type: 'success'
              })
              this.$router.push('/achievements')
            } else {
              console.log(response)
            }
          } catch (err) {
            err.response.data.messages.forEach(message => {
              this.$message.error(`${message}`)
            })
          }
        }
      } else {
        this.$message.error('Asegurate que no falte ningun archivo o dato')
      }
    },
    traslatedType (contain) {
      switch (contain) {
        case 'programmed':
          return 'Programado'
        case 'qr':
          return 'Código QR'
        case 'customized':
          return 'Personalizado'
        default:
          return contain
      }
    }
  }
}
</script>

<style>
.label-form-validate-wrong {
  color: #F56C6C;
}

.size-form-item>.el-form-item {
  margin: 0;
}

.el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
  line-height: 1em;
}

.my-autocomplete {
  width: 500px;
}
</style>
