import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/achievement/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/achievement/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getAchievement (id) {
  return request({
    url: `/api-manager/achievement/${id}`,
    method: 'get'
  })
}

export function createAchievement (data) {
  return request({
    url: '/api-manager/achievement',
    method: 'post',
    data
  })
}

export function updateAchievement (data, id) {
  return request({
    url: `/api-manager/achievement/${id}`,
    method: 'put',
    data
  })
}

export function deleteAchievement (id) {
  return request({
    url: `/api-manager/achievement/${id}`,
    method: 'delete'
  })
}

export function filterData (data) {
  return request({
    url: '/api-manager/achievement/filterData',
    method: 'post',
    data
  })
}
